import React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found"/>
    <h1>Page not found</h1>
    <p>This page doesn't seem to exist.</p>
    <p>It might have been moved. You can go back to the main page and try to find the page this way.</p>
    <p><Link to="/">Go back to my main page</Link></p>
  </>
)

export default NotFoundPage
